<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Use the standard enthalpies of formation available
        <a
          href="https://cnx.org/contents/RTmuIxzM@9.17:2gdkQpYU@10/Standard-Thermodynamic-Properties-for-Selected-Substances"
          rel="noopener noreferrer"
          target="_blank"
        >
          here</a
        >
        to calculate the standard enthalpy of reaction,
        <stemble-latex content="$\Delta\text{H}^\circ_{\text{rxn}},$" />
        for the reaction shown below.
      </p>

      <p class="mb-4 pl-6">
        <chemical-latex content="N2O4(g) + 3 CO(g) -> N2O(g) + 3 CO2(g)" />
      </p>

      <calculation-input
        v-model="inputs.reactionEnthalpy"
        prepend-text="$\Delta\text{H}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question82',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        reactionEnthalpy: null,
      },
    };
  },
};
</script>
